<template>
  <ik-data-table
    :custom-on-row-click="()=>{}"
    :entity-name="$t('changeRecord')"
    :model="model"
    :required-date-value="dates"
    :use-date-filter="true"
    :use-default-action="false"
    :use-edit-action="false"
  />
</template>

<script>
  import PageHeader from '@/composable/InK/typography/PageHeader.vue'
  import Records from '@/model/storage/Records'
  import { IkDataTable } from 'metaflow-js'
  import { today } from 'metaflow-js/EntityFrameWork/DateRepository'

  export default {
    name: 'StorageRecords',
    components: { PageHeader, IkDataTable },
    data: function () {
      return {
        model: Records,
        dates: [today, today],
      }
    },
    mounted () {
      this.dates = [today, today]
    },
  }
</script>

<style scoped>

</style>
